.container {
    min-height: 50vh;
    margin-top: var(--spacing-core-12, 48px);
    margin-bottom: var(--spacing-core-12, 48px);
    max-width: 1488px;
}

.title {
    margin-bottom: var(--spacing-core-4, 16px);
}

.description {
    margin-bottom: var(--spacing-core-6, 24px);
}

.link {
    text-decoration: underline dotted 1px;
    text-underline-offset: 4px;
    background-color: transparent;
    border: none;
    padding: 0;
    color: inherit;
    font-family: inherit;
    font-size: inherit;

    &:hover {
        cursor: pointer;
    }
}

.quote {
    color: #5a5a5a;
}
